/* REACT MODULES  */
import React from "react"

/* MATERIAL UI */
import {
  Grid,
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core"

/* LOCAL FILES */
import ArrowDown from "../images/arrow-Down.png"
import Grupo1 from "../images/Grupo_184.svg"
import Grupo2 from "../images/Grupo_185.svg"
import Grupo3 from "../images/Grupo_186.svg"
import RH from "../images/RH.png"
import Valor_Agregado from "../images/Valores.png"
import Defensa from "../images/Defensa_Legal.png"
import Consultoria from "../images/Consultoria.png"
import Reportes from "../images/Reportes.svg"
import Imss from "../images/Imss.svg"
import Impuestos from "../images/Impuestos.svg"
import Contratacion from "../images/Contratacion.svg"
import Seleccion from "../images/Seleccion.svg"
import Defensa3 from "../images/DF3.png"
import Defensa2 from "../images/DF2.png"
import Defensa1 from "../images/DF1.png"
import Candado from "../images/candado.svg"
import Elipse from "../images/Elipse.svg"
import Check from "../images/Check.svg"
import Sprinlabs from "../images/springlabs.svg"
import Elizondo from "../images/elizondo.png"
import Villacasas from "../images/logo@2x-vc.png"
import Movistar from "../images/Grupo 183.png"
import Salads from "../images/Salads.png"
import Bancomer from "../images/2498px-BBVA_2019.svg.png"
import FormContact from "../components/FormContact/FormContact"

/* STYLES */
import useStyles from "./SectionOne-Page2-Stylos"

function SectionOnePage2() {
  const classes = useStyles()
  return (
    <div>
      <Grid container>
        <Grid item xs className={classes.Contenedor1}>
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="center"
            className={classes.Contenedor2}
          >
            <Typography className={classes.TituloAzul}> EXPERTOS</Typography>
            <Typography className={classes.TituloBlanco}>
              EN ADMINISTRACIÓN DE <br />
              RECURSOS HUMANOS
            </Typography>
            <Typography className={classes.Parrafo}>
              Ofrecemos diferentes alternativas que sean acordes a sus
              necesidades y <br />
              cumpliendo los mandatos jurídicos dentro de un marco de
              transparencia, <br />
              acorde a las obligaciones laborales y fiscales que marcan la ley.
            </Typography>
            <FormContact type="Button" />

            <Typography className={classes.arrowContent}>
              <img src={ArrowDown} className={classes.arrow} alt="Arrow" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          xs
          className={classes.ContenedorBlanco}
          direction="column"
          justify="flex-end"
          alignItems="center"
        >
          <Typography className={classes.TituloAzul2}> ENFOCADOS</Typography>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid className={classes.DividerAzul}> &nbsp;</Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              md={2}
              lg={2}
              xl={2}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Grupo1}
                  className={classes.avatars}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              md={2}
              lg={2}
              xl={2}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Grupo2}
                  className={classes.avatars}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              md={2}
              lg={2}
              xl={2}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Grupo3}
                  className={classes.avatars}
                />
              </Grid>
            </Grid>
            <Grid></Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography className={classes.Parrafo2}>
                  En las áreas fiscal, contable y laboral, adaptándonos siempre
                  a las necesidades y características de <br />
                  nuestros clientes.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          xs
          className={classes.ContenedorBlanco2}
          direction="column"
          justify="flex-end"
          alignItems="center"
        >
          <Typography className={classes.TituloAzul3}> SOLUCIONES</Typography>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid className={classes.DividerAzul}> &nbsp;</Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={2} lg={2} xl={2}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={RH}
                  alt="Recursos humanos"
                  className={classes.cardImage}
                />
              </Grid>
            </Grid>

            <Grid item xs={6} md={2} lg={2} xl={2}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Valor_Agregado}
                  alt="Valores Agregados"
                  className={classes.cardImage}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={2} lg={2} xl={2}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Defensa}
                  alt="Defensa Legal"
                  className={classes.cardImage}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={2} lg={2} xl={2}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Consultoria}
                  alt="Consultoria Fiscal"
                  className={classes.cardImage}
                />
              </Grid>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Grid>
      </Grid>
      <section id="Recursos_Humanos">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs
            className={classes.ContenedorGris}
            direction="column"
            justify="flex-end"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.Containter}
            >
              <FormContact type="Button" className={classes.ButtonForm} />
            </Grid>
            <Typography className={classes.TituloAzul33}>
              RECURSOS HUMANOS
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                ></Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.Parrafo22}>
                    Su personal será contratado por nuestra empresa cumpliendo
                    con las obligaciones patronales, tales como las relativas a
                    seguridad social e incluyendo beneficios extra legales para
                    mejorar las condiciones de los trabajadores.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          xs
          className={classes.ContenedorBlanco}
          direction="column"
          justify="flex-end"
          alignItems="center"
        >
          <Typography className={classes.TituloAzul3}>BENEFICIOS</Typography>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={6}
              md={12}
              lg={12}
              xl={12}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid className={classes.DividerAzul}> &nbsp;</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography className={classes.Parrafo3}>
                  Ahorros en cargas administrativas, tales como timbrado de los
                  recibos de nómina, comisiones bancarias por
                  <br />
                  movimientos de dispersión de nómina y por el cálculo y
                  procesamiento de la misma. <br /> &nbsp;
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={6} md={3} lg={3} xl={3}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Seleccion}
                  className={classes.avatars}
                />
                <Grid item xs={12} md={12}>
                  <Typography className={classes.TexoBen}>
                    Reclutamiento y Selección de Personal
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} md={3} lg={3} xl={3}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Contratacion}
                  className={classes.avatars}
                />
                <Typography className={classes.TexoBen}>
                  Contratación de personal <br /> (empleados y/o asimilados a
                  salarios).
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              lg={3}
              xl={3}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Impuestos}
                  className={classes.avatars}
                />
                <Typography className={classes.TexoBen}>
                  Retención y pago de impuestos.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={6}
              md={3}
              lg={3}
              xl={3}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Imss}
                  className={classes.avatars}
                />
                <Typography className={classes.TexoBen}>
                  Movimientos ante el IMSS.
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              md={3}
              lg={3}
              xl={3}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Reportes}
                  className={classes.avatars}
                />
                <Typography className={classes.TexoBen}>
                  Reportes mensuales y/o bimestrales de <br /> pagos de IMSS e
                  INFONAVIT
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              lg={3}
              xl={3}
              className={classes.ContenedorCirculo}
            ></Grid>
          </Grid>
        </Grid>
      </Grid>
      <section id="Valores_Agregados">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs
            className={classes.ContenedorFondo2}
            direction="column"
            justify="flex-end"
            alignItems="center"
          >
            <Typography className={classes.TituloAzulBlanco}>
              {" "}
              VALORES AGREGADOS
            </Typography>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid className={classes.DividerBlanco}> &nbsp;</Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card1}>
                    <CardHeader
                      title={
                        <Typography className={classes.TituloCard}>
                          {" "}
                          SEGURO DE GASTOS MÉDICOS <br />
                          MAYORES
                        </Typography>
                      }
                      subheader={
                        <Typography className={classes.SubTituloCard}>
                          {" "}
                          (Costo preferencial)
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Typography className={classes.TextoCard}>
                        Suma asegurada $3,000,000.00 <br /> &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        Deducible $ 6,000.00 <br /> &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        Costo de seguro de 10% <br /> &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        Topado a $30,000.00
                        <br /> &nbsp;
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4} lg={4} xl={4}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card1}>
                    <CardHeader
                      title={
                        <Typography className={classes.TituloCard}>
                          {" "}
                          SEGURO DE VIDA Y GASTOS <br /> FUNERARIOS
                        </Typography>
                      }
                      subheader={
                        <Typography className={classes.SubTituloCard}>
                          {" "}
                          (Sin costo).
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Typography className={classes.TextoCard}>
                        Suma asegurada Muerte Natural por $110,000.00 <br />{" "}
                        &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        Accidental por $ 220,000.00 <br /> &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        Incapacidad Total y Permanente por $110,000.00 <br />{" "}
                        &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        Gastos Funerarios por $30,000.00
                        <br /> &nbsp;
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card1}>
                    <CardHeader
                      title={
                        <Typography className={classes.TituloCard}>
                          {" "}
                          SEGUROS DE AUTOMÓVIL, CASA <br /> ETC.
                        </Typography>
                      }
                      subheader={
                        <Typography className={classes.SubTituloCard}>
                          {" "}
                          (Costo preferencial)
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Typography className={classes.TextoCard}>
                        Descuento vía nómina (Costo preferencial) <br /> &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        <br /> &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        <br /> &nbsp;
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        <br /> &nbsp;
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card2}>
                    <CardHeader
                      title={
                        <Typography className={classes.TituloCard}>
                          PRÉSTAMOS
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Typography className={classes.TextoCard}>
                        Descuento vía nómina <br /> &nbsp;
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card2}>
                    <CardHeader
                      title={
                        <Typography className={classes.TituloCard}>
                          CAJA DE AHORRO
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Typography className={classes.TextoCard}>
                        Ciclo anual con intereses variables <br /> &nbsp;
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card2}>
                    <CardHeader
                      title={
                        <Typography className={classes.TituloCard}>
                          TARJETA ONE CARD
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Typography className={classes.TextoCard}>
                        Que ofrece los servicios de vales de despensa, <br />
                        tarjeta multi comercios.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                item
                xs={10}
                md={4}
                lg={4}
                xl={4}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card3}>
                    <CardContent>
                      <Typography className={classes.TituloCard}>
                        {" "}
                        ADQUISICIÓN DE ARTÍCULOS
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        De línea blanca, electrónicos, electrodomésticos, <br />
                        muebles con descuentos vía nómina. <br /> &nbsp;
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card3}>
                    <CardContent>
                      <Typography className={classes.TituloCard}>
                        {" "}
                        CONVENIO
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        Con centro Óptico Profesional, que ofrece los <br />
                        servicios de anteojos con precios preferenciales, <br />
                        descuentos vía nómina. <br /> &nbsp;
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Card className={classes.Card33}>
                    <CardContent>
                      <Typography className={classes.TituloCard}>
                        {" "}
                        CLUB SALUD FAMILIAR
                      </Typography>
                      <Typography className={classes.TextoCard}>
                        Que ofrece consultas médicas y servicios de <br />
                        imagen, radiología, laboratorios etc. A un costo <br />
                        preferencial.
                        <br />
                        &nbsp;
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section id="Defensa_Legal">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs
            className={classes.ContenedorBlanco}
            direction="column"
            justify="flex-end"
            alignItems="center"
          >
            <Typography className={classes.TituloAzul2}>
              {" "}
              DEFENSA LEGAL
            </Typography>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.ContenedorCirculo}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid className={classes.DividerAzul}> &nbsp;</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.Parrafo3}>
                    Contamos con un equipo de abogados especialistas en el área
                    laboral para <br /> ofrecerle sin costo:
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} lg={3} xl={3}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={Defensa1}
                    className={classes.avatars}
                  />
                  <Typography className={classes.TexoBen}>
                    Atención y Asesoría Jurídica (conflictos <br /> individuales
                    de trabajo, citas conciliatorias).
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3} lg={3} xl={3}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={Defensa2}
                    className={classes.avatars}
                  />
                  <Typography className={classes.TexoBen}>
                    Gestiones legales por bajas de empleados <br />
                    (convenios, renuncias, rescisiones, finiquitos) <br />
                    aportando el cliente el costo de la liquidación.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} lg={3} xl={3}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={Defensa3}
                    className={classes.avatars}
                  />
                  <Typography className={classes.TexoBen}>
                    Asesoría jurídica y gestiones ante autoridades del <br />
                    trabajo (secretaría del trabajo, procuraduría
                    <br /> del trabajo).
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section id="Consultoria_Fiscal">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs
            className={classes.ContenedorFondo3}
            direction="column"
            justify="flex-end"
            alignItems="center"
          >
            <Typography className={classes.TituloAzul22}>
              {" "}
              CONSULTORÍA FISCAL{" "}
            </Typography>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6} md={12} lg={12} xl={12}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid className={classes.DividerAzul}> &nbsp;</Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.Parrafo3}>
                    Diseñamos un modelo de trabajo de acuerdo a tus necesidades
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          xs
          className={classes.ContenedorBlanco3}
          direction="column"
          justify="flex-end"
          alignItems="center"
        >
          <Typography className={classes.TituloAzul22}>
            {" "}
            SIÉNTETE SEGURO
          </Typography>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid className={classes.DividerAzul}> &nbsp;</Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              xl={3}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Candado}
                  className={classes.avatars}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography className={classes.Parrafo3}>
                  Se establece una relación comercial entre su empresa y
                  nosotros bajo el esquema de <br />
                  un contrato de prestación de servicios profesionales.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid>
                  <img src={Elipse} alt="Elipse" />
                </Grid>
                <Grid>
                  <img src={Elipse} alt="Elipse" />
                </Grid>
                <Grid>
                  <img src={Elipse} alt="Elipse" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography className={classes.Parrafo3}>
                  Con la intención de proteger a nuestros clientes celebramos un
                  convenio de <br />
                  confidencialidad.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid>
                  <img src={Elipse} alt="Elipse" />
                </Grid>
                <Grid>
                  <img src={Elipse} alt="Elipse" />
                </Grid>
                <Grid>
                  <img src={Elipse} alt="Elipse" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography className={classes.Parrafo3}>
                  Aviso de Privacidad.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              lg={3}
              xl={3}
              className={classes.ContenedorCirculo}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Check}
                  className={classes.avatars}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          xs
          className={classes.ContenedorBlanco2}
          direction="column"
          justify="flex-end"
          alignItems="center"
        >
          <Typography className={classes.TituloAzul22}> COMPROBADO</Typography>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6} md={12} lg={12} xl={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid className={classes.DividerAzul}> &nbsp;</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography className={classes.Parrafo4}>
                  Ellos ya conocen nuestro trabajo, únete a nuestros clientes
                  satisfechos.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Sprinlabs}
                  alt="Springlabs"
                  className={classes.ImagenLogo}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Elizondo}
                  alt="Elizondo"
                  className={classes.ImagenLogo}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Bancomer}
                  alt="Bancomer"
                  className={classes.ImagenLogo}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Salads}
                  alt="Super Salads"
                  className={classes.ImagenLogo}
                />
              </Grid>
            </Grid>

            <Grid item xs={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Movistar}
                  alt="Movistar"
                  className={classes.ImagenLogo}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src={Villacasas}
                  alt="Villacasas"
                  className={classes.ImagenLogo}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={12} lg={12} xl={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <FormContact type="Button" />
              </Grid>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SectionOnePage2
