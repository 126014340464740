import React, { useState, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import Button from "@material-ui/core/Button"
import Background from "../images/calculatorBackground.png"
import TextField from "@material-ui/core/TextField"
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"
import Table from "../components/Table/Table"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import EditIcon from "@material-ui/icons/Edit"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import Notification from "../components/Notification/Notification"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import UseApi from "../hooks/useApi"
import { GET_USERS, POST_USERS, PUT_USERS } from "../constanst/endpoints"
import Progres from "../components/Progress"
import Modal from "../components/Modal"
import { getCurrentUser } from "../utils/auth"
import { navigate } from "gatsby"

/* STYLES */
const useStyles = makeStyles(theme => ({
  icons: { color: "#1B5196", cursor: "pointer" },
}))

const TransparentButton = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 10,
    border: 0,
    color: "#1B5196",
    height: 80,
    "&:hover, &$focusVisible": {
      backgroundColor: "transparent",
      color: "#94DBF6",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button)

const StyledButton = withStyles({
  root: {
    background: "#1B5196",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "182px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
  },
  label: {
    textTransform: "capitalize",
    fontFamily: "Helvetica",
    font: "Regular 20px/24px Helvetica Neue",
    "&:hover, &$focusVisible": {
      color: "#1B5196",
    },
  },
})(Button)

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default function Users() {
  const classes = useStyles()
  const [openAdd, setOpenAdd] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [userID, setUserID] = useState("")
  const [name, setName] = useState("")
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [role, setRole] = useState("")
  const [password, setPassword] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [mensajeModal, setMensajeModal] = useState("")
  const { response, errorApi, get, post } = UseApi()
  const { response: responseDelete, post: postDelete } = UseApi()
  const user = getCurrentUser()
  const disabledButton =
    name && username && email && phone && role && password ? false : true

  useEffect(() => {
    const user = getCurrentUser()
    if (user.Name_Role === "asesor") {
      navigate("/app/calculator")
    } else {
      getListUsers()
    }
  }, ["input"])

  useEffect(() => {
    if (errorApi.length > 0) {
      setMensajeModal("Se produjo un error en el servidor")
      setshowModal(true)
      setShowProgress(false)
    }
  }, [errorApi])

  useEffect(() => {
    const { payload, headerResponse } = response
    if (headerResponse && headerResponse.code !== 200) {
      const { message } = headerResponse
      setMensajeModal(message)
      setshowModal(true)
    }
    if (payload) {
      setShowProgress(false)
      const { allUsers, newUser, update } = payload
      if (allUsers) {
        getUserItems(allUsers)
      }
      if (newUser || update) {
        const message = newUser
          ? "Se agrego correctamente"
          : "Se edito correctamente"
        setMensajeModal(message)
        setshowModal(true)
        getListUsers()
      }
    }
  }, [response])

  useEffect(() => {
    const { payload, headerResponse } = responseDelete
    if (headerResponse && headerResponse.code !== 200) {
      const { message } = headerResponse
      setMensajeModal(message)
      setshowModal(true)
    }
    if (userID) {
      setShowProgress(false)
      setMensajeModal("Se eliminó correctamente el usuario")
      setOpenDelete(false)
      setshowModal(true)
      getListUsers()
    }
  }, [responseDelete])

  const getUserItems = allIsr => {
    const userItems = allIsr.map(item => {
      let Role = ""
      if (item.id_Role === "5edfa9168ef939485483df3e") {
        Role = "Administrador"
      } else {
        Role = "Asesor enlacce"
      }
      return [
        item.User_Name,
        item.User_Username,
        item.User_Mail,
        item.User_Phone,
        Role,
        <EditIcon
          className={classes.icons}
          onClick={() => {
            setOpenEdit(true)
            setName(item.User_Name)
            setUsername(item.User_Username)
            setEmail(item.User_Mail)
            setPhone(item.User_Phone)
            setRole(item.id_Role)
            setPassword(item.User_Pass)
            setUserID(item._id)
          }}
        />,
        <DeleteOutlineIcon
          className={classes.icons}
          onClick={() => {
            setOpenDelete(true)
            setUserID(item._id)
          }}
        />,
      ]
    })
    setUsersList(userItems)
  }

  const getListUsers = async () => {
    await setShowProgress(true)
    const headers = {
      Authorization: `${user.Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: GET_USERS,
      headers,
    }
    await get(query)
  }

  const addUser = async () => {
    await setShowProgress(true)
    const headers = {
      Authorization: `${user.Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: POST_USERS,
      headers,
      data: {
        User_Name: name,
        User_Username: username,
        User_Pass: password,
        roleName: role,
        User_Phone: phone,
        User_Mail: email,
      },
    }
    await post(query)
    cleanState()
  }

  const editUser = async () => {
    await setShowProgress(true)
    const headers = {
      Authorization: `${user.Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: PUT_USERS,
      headers,
      data: {
        _id: userID,
        User_Name: name,
        User_Username: username,
        User_Pass: password,
        roleName: role,
        User_Phone: phone,
        User_Mail: email,
      },
    }
    await post(query)
    cleanState()
  }

  const deleteUser = async () => {
    await setShowProgress(true)
    const headers = {
      Authorization: `${user.Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: PUT_USERS,
      headers,
      data: {
        _id: userID,
        User_Status: 0,
      },
    }
    await postDelete(query)
    cleanState()
  }

  const cleanState = () => {
    setUserID("")
    setName("")
    setUsername("")
    setEmail("")
    setPhone("")
    setRole("")
    setPassword("")
  }

  return (
    <>
      <Notification
        okTitle="Guardar"
        cancelTitle="Cancelar"
        open={openAdd}
        title="Agregar usuario"
        disabledButton={disabledButton}
        content={
          <Grid
            container
            xs={12}
            css={css`
              margin-top: 30px;
            `}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Nombre
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={e => setName(e.target.value)}
                value={name}
                name="name"
                id="name"
                placeholder="Juan Mendez Mendez"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Nombre de usuario
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={e => setUsername(e.target.value)}
                value={username}
                name="username"
                id="username"
                placeholder="JuanMendez"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Correo
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                value={email}
                name="email"
                id="email"
                placeholder="JuanMendez@gmail.com"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Numero celular
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={e => setPhone(e.target.value)}
                value={phone}
                name="phone"
                id="phone"
                placeholder="5512345678"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Rol
              </Typography>
              <Select
                variant="outlined"
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                onChange={e => {
                  setRole(e.target.value)
                }}
              >
                <MenuItem value="administrador">Administrador</MenuItem>
                <MenuItem value="asesor">Asesor</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Contraseña
              </Typography>
              <TextField
                type="text"
                fullWidth
                variant="outlined"
                onChange={e => setPassword(e.target.value)}
                value={password}
                name="password"
                id="password"
                placeholder="123"
              />
            </Grid>
          </Grid>
        }
        onClose={() => {
          setOpenAdd(false)
          cleanState()
        }}
        onOk={() => {
          setOpenAdd(false)
          addUser()
        }}
      />
      <Notification
        okTitle="Guardar"
        cancelTitle="Cancelar"
        open={openEdit}
        title="Editar usuario"
        disabledButton={disabledButton}
        content={
          <Grid
            container
            xs={12}
            css={css`
              margin-top: 30px;
            `}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Nombre
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={e => setName(e.target.value)}
                value={name}
                name="name"
                id="name"
                placeholder="Juan Mendez Mendez"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Nombre de usuario
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={e => setUsername(e.target.value)}
                value={username}
                name="username"
                id="username"
                placeholder="JuanMendez"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Correo
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                value={email}
                name="email"
                id="email"
                placeholder="JuanMendez@gmail.com"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Numero celular
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={e => setPhone(e.target.value)}
                value={phone}
                name="phone"
                id="phone"
                placeholder="5512345678"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Rol
              </Typography>
              <Select
                variant="outlined"
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                onChange={e => {
                  setRole(e.target.value)
                }}
              >
                <MenuItem value="administrador">Administrador</MenuItem>
                <MenuItem value="asesor">Asesor</MenuItem>
              </Select>
            </Grid>
          </Grid>
        }
        onClose={() => {
          setOpenEdit(false)
          cleanState()
        }}
        onOk={() => {
          setOpenEdit(false)
          editUser()
        }}
      />
      <Notification
        deleteNotification
        okTitle="Guardar"
        cancelTitle="Cancelar"
        open={openDelete}
        title="Eliminar usuario"
        content="¿Esta seguro que desea eliminar el usuario?"
        onClose={() => setOpenDelete(false)}
        onOk={() => deleteUser()}
      />
      <Grid
        container
        direction="column"
        alignItems="center"
        css={css`
          width: 100%;
          height: 100%;
          min-height: 800px;
          background-image: url(${Background});
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: 0% 0%;
          @media (max-width: 768px) {
            height: auto;
          }
        `}
      >
        <Grid
          css={css`
            margin-top: 10%;
            @media (max-width: 768px) {
              height: auto;
            }
          `}
        >
          <Typography
            css={css`
              color: #1b5196;
              font-size: 45px !important;
              font-weight: 700 !important;
              padding-right: 20px;
              letter-spacing: 6.62px !important;
            `}
          >
            USUARIOS
          </Typography>
        </Grid>
        <Grid
          container
          xs={10}
          direction="column"
          alignItems="center"
          css={css`
            margin-top: 25px;
            margin-bottom: 25px;
            padding: 30px;
            background-color: #ffffff8e;
            min-height: 450px;
            border-radius: 20px;
            @media (max-width: 768px) {
              height: auto;
            }
          `}
        >
          <Grid container xs={12}>
            <TransparentButton onClick={() => setOpenAdd(true)}>
              <AddCircleIcon style={{ fontSize: "60", marginRight: "10px" }} />
              AGREGAR
            </TransparentButton>
          </Grid>
          <Table
            tableHeaderColor="background"
            tableHead={[
              "Nombre",
              "Usuario",
              "Correo",
              "Teléfono",
              "Puesto",
              "Editar",
              "Borrar",
            ]}
            tableData={usersList}
          />
        </Grid>
      </Grid>
      <Progres show={showProgress} />
      {showModal && (
        <Modal noButton>
          <Typography className={classes.cardText}>{mensajeModal}</Typography>
          <StyledButton onClick={() => setshowModal(false)}>
            Cerrar
          </StyledButton>
        </Modal>
      )}
    </>
  )
}
