/**
 *  @version 1.0.1
 *  @author Springlabs - Luis Hernandez
 *  @description Componente para mostrar el modal el cual responde a la preguntaa ¿aplica retencion de iva?
 * 	@process 2
 */

/* REACT MODULES */
import React from "react"
import PropTypes from "prop-types"

/* MATERIAL UI */
import { makeStyles } from "@material-ui/core/styles"
import {
  Dialog,
  DialogTitle,
  Portal,
  Typography,
  Grid,
  DialogContentText,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined"
import AdjustIcon from "@material-ui/icons/Adjust"
import Button from "./Button"

const useStyles = makeStyles(theme => ({
  TitleModalIva: {
    color: "#1B5196",
    fontSize: "23px",
  },
  SubTitleModalIva: {
    color: "#1B5196",
    fontSize: "16px",
    textAlign: "center",
  },
}))

const ModalIva = ({ open, maxWidth, activateFucntion, calculate }) => {
  const classes = useStyles()

  return (
    <Portal>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        id="galileo-dialog"
        aria-labelledby="dialog-title"
      >
        <DialogTitle>
          <Grid
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.TitleModalIva}>
              ¡IMPORTANTE!
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography className={classes.SubTitleModalIva}>
              Indícanos si deseas aplicar la retención de IVA
            </Typography>
            <Grid
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    icon={<Brightness1OutlinedIcon />}
                    checkedIcon={<AdjustIcon color="primary" />}
                    name="checkedH"
                  />
                }
                label={
                  <Typography className={classes.Label}>
                    Aplica retención de IVA
                  </Typography>
                }
                onChange={() => activateFucntion()}
                className={classes.RadioButtonTex}
              />
            </Grid>
            <Grid
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button onClick={calculate}>Enviar </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Portal>
  )
}

ModalIva.propTypes = {
  show: PropTypes.bool,
  activateFucntion: PropTypes.func,
  calculate: PropTypes.func,
}

ModalIva.defaultProps = {
  show: false,
  activateFucntion: () => {},
  calculate: () => {},
}

export default ModalIva
