/* REACT MODULES*/
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"
import { navigate } from "gatsby"

/* MATERIAL UI */
import { Grid, Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { withStyles, makeStyles } from "@material-ui/core/styles"

/* LOCAL FILES */
import { GET_VALUES, PUT_VALUES } from "../constanst/endpoints"
import { getCurrentUser } from "../utils/auth"
import Background from "../images/calculatorBackground.png"
import UseApi from "../hooks/useApi"
import Progres from "./Progress"
import Modal from "./Modal"

const useStyles = makeStyles(theme => ({
  icons: { color: "#1B5196", cursor: "pointer" },
}))

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
}

const StyledButton = withStyles({
  root: {
    background: "#1B5196",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "182px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
  },
  label: {
    textTransform: "capitalize",
    fontFamily: "Helvetica",
    font: "Regular 20px/24px Helvetica Neue",
    "&:hover, &$focusVisible": {
      color: "#1B5196",
    },
  },
})(Button)

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default function Values() {
  const classes = useStyles()
  const [valueID, setValueID] = useState("")
  const [uma, setUma] = useState("")
  const [minSalary, setMinSalary] = useState("")
  const [maxSalary, setMaxSalary] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [mensajeModal, setMensajeModal] = useState("")
  const [session, setSession] = useState("")
  const { response, errorApi, get, post } = UseApi()
  const validateButton = uma.length > 0 && minSalary.length > 0 ? false : true

  const onClickFunction = () => {
    const { Token } = session
    editValues(Token)
  }

  useEffect(() => {
    const user = getCurrentUser()
    if (user.Name_Role === "asesor") {
      navigate("/app/calculator")
    } else {
      setSession(user)
    }
  }, ["input"])

  useEffect(() => {
    const { Token } = session
    if (Token) {
      getValues(Token)
    }
  }, [session])

  useEffect(() => {
    if (errorApi.length > 0) {
      setMensajeModal("Se produjo un error en el servidor")
      setshowModal(true)
      setShowProgress(false)
    }
  }, [errorApi])

  useEffect(() => {
    const { payload, headerResponse } = response
    if (headerResponse && headerResponse.code !== 200) {
      const { message } = headerResponse
      setShowProgress(false)
      setMensajeModal(message)
      setshowModal(true)
    }

    if (payload) {
      setShowProgress(false)
      const { queryValores, update } = payload
      if (queryValores) {
        getValueItems(queryValores)
      }
      if (update) {
        setMensajeModal("Se actualizo correctamente")
        setshowModal(true)
        getValues()
      }
    }
  }, [response])

  const getValueItems = allValues => {
    setValueID(allValues[0]._id)
    setUma(allValues[0].UMA)
    setMinSalary(allValues[0].SALARY_MIN)
    setMaxSalary(allValues[0].SALARY_TOP)
  }

  const getValues = async () => {
    const { Token } = session
    await setShowProgress(true)
    const headers = {
      Authorization: `${Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: GET_VALUES,
      headers,
    }
    await get(query)
  }

  const editValues = async () => {
    const { Token } = session
    await setShowProgress(true)
    const headers = {
      Authorization: `${Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: PUT_VALUES,
      headers,
      data: {
        _id: valueID,
        SALARY_MIN: minSalary,
        UMA: uma,
      },
    }
    await post(query)
  }

  return (
    <>
      {" "}
      <Grid
        container
        direction="column"
        alignItems="center"
        css={css`
          width: 100%;
          height: 100%;
          min-height: 800px;
          background-image: url(${Background});
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: 0% 0%;
          @media (max-width: 768px) {
            height: auto;
          }
        `}
      >
        <Grid
          css={css`
            margin-top: 10%;
            @media (max-width: 768px) {
              height: auto;
            }
          `}
        >
          <Typography
            css={css`
              color: #1b5196;
              font-size: 45px !important;
              font-weight: 700 !important;
              padding-right: 20px;
              letter-spacing: 6.62px !important;
            `}
          >
            VALORES
          </Typography>
        </Grid>
        <Grid
          container
          xs={10}
          direction="column"
          alignItems="center"
          css={css`
            margin-top: 25px;
            padding: 30px;
            background-color: #ffffff8e;
            min-height: 450px;
            border-radius: 20px;
            @media (max-width: 768px) {
              height: auto;
            }
          `}
        >
          <Grid item css={css``}>
            <Typography
              css={css`
                color: #1b5196;
                font-size: 32px !important;
                padding-right: 20px;
              `}
            >
              Constantes del año en curso
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            css={css`
              margin-top: 30px;
            `}
          >
            <Grid
              item
              xs={12}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Salario mínimo
              </Typography>
              <TextField
                fullWidth
                value={minSalary}
                onChange={e => setMinSalary(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input"
                placeholder="000.00"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Uma:
              </Typography>
              <TextField
                fullWidth
                value={uma}
                onChange={e => setUma(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input"
                placeholder="000.00"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              css={css`
                padding: 10px;
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 15px !important;
                `}
              >
                Salario tope:
              </Typography>
              <TextField
                fullWidth
                disabled
                value={maxSalary}
                onChange={e => setMaxSalary(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input"
                placeholder="000.00"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickFunction}
            disabled={validateButton}
            css={css`
              border-radius: 50px !important;
              margin-top: 80px !important;
              width: 182px;
              height: 45px;
              font-size: 20px !important;
              color: #fff !important;
              @media (max-width: 768px) {
              }
            `}
          >
            ENVIAR
          </Button>
        </Grid>
      </Grid>
      <Progres show={showProgress} />
      {showModal && (
        <Modal noButton>
          <Typography className={classes.cardText}>{mensajeModal}</Typography>
          <StyledButton onClick={() => setshowModal(false)}>
            Cerrar
          </StyledButton>
        </Modal>
      )}
    </>
  )
}
