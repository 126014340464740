import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Table from "@material-ui/core/Table"
import tableStyle from "./tableStyle"
import Pagination from "@material-ui/lab/Pagination"

import {
  TableHead,
  TableRow,
  TablePagination,
  TableBody,
  TableCell,
  MenuItem,
  Select,
  FormControl,
  Grid,
} from "@material-ui/core"

const CustomTable = ({ ...props }) => {
  const { classes, tableHead, tableData, tableBodyWhite, total } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1)
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((prop, key) => {
              if (total) {
                return (
                  <TableRow
                    key={key}
                    className={
                      prop === tableData[tableData.length - 1]
                        ? classes.tableBodyTotal
                        : classes.tableBodyRow
                    }
                  >
                    {prop.map((prop, key) => {
                      return (
                        <TableCell
                          className={
                            tableBodyWhite
                              ? classes.tableBodyWhite
                              : classes.tableCell
                          }
                          key={key}
                        >
                          {prop}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              } else {
                return (
                  <TableRow key={key} className={classes.tableBodyRow}>
                    {prop.map((prop, key) => {
                      return (
                        <TableCell
                          className={
                            tableBodyWhite
                              ? classes.tableBodyWhite
                              : classes.tableCell
                          }
                          key={key}
                        >
                          {prop}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              }
            })}
        </TableBody>
      </Table>
      <TablePagination
        style={{ display: "none" }}
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid>
          <Pagination
            count={Math.ceil(tableData.length / rowsPerPage)}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
            page={page + 1}
            color="primary"
          />
        </Grid>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            <MenuItem value={10}>10/Page</MenuItem>
            <MenuItem value={25}>25/Page</MenuItem>
            <MenuItem value={30}>30/Page</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </div>
  )
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
}

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
}

export default withStyles(tableStyle)(CustomTable)
