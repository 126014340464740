import React from "react"
import Calcular from "./Calculadora/Calculadora"

const Calculadora = () => (
  <div>
    <Calcular />
  </div>
)

export default Calculadora
