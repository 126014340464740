import React from "react"
import SectionOne from "./SectionOne-Page2";

const Page2 = () => (
    <div>
        <SectionOne />
    </div>
)

export default Page2
