const tableStyle = () => ({
  title: {
    color: "#1b5196",
    fontSize: "45px",
    fontWeight: "700",
    letterSpacing: "6.62px",
    textTransform: "uppercase",
  },
  titleDelete: {
    color: "#1b5196",
    fontSize: "25px",
    fontWeight: "700",
    letterSpacing: "6.62px",
    textTransform: "uppercase",
    paddingTop: "40px",
  },
  contentDelete: {
    color: "#1b5196",
    justifyContent: "center",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: "1px",
    right: "1px",
  },
})

export default tableStyle
