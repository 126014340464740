import { makeStyles, withStyles } from "@material-ui/core/styles"
import BackGraund from "../../images/993.png"
import { Button as MyButton } from "@material-ui/core"

export const useStyles = makeStyles(theme => ({
  contenedor1: {
    width: "100%",
    height: "100%",
    marginTop: "-1.5rem",
    backgroundImage: `url(${BackGraund})`,
    backgroundRepeat: "repeat-y;",
    backgroundSize: "cover",
  },
  Titulo: {
    color: "#1B5196",
    textAlign: "center",
    fontSize: "55px",
    letterSpacing: "6.62px",
    fontFamily: "Helvetica",
    font: "Bold 45px/55px Helvetica Neue",
    marginTop: "15rem",
  },
  ContenedorGlobal: {
    backgroundColor: "#FFFFFF8E",
    borderRadius: "20px",
    marginBottom: "2rem",
    width: "1088px",
    height: "auto",
    "@media (max-width: 600px)": {
      height: "auto",
      width: "20%",
      marginLeft: "40rem",
    },
  },
  ContenedorTabs: {
    color: "#1B5196",
    marginTop: "1rem",
    height: "3rem",
    borderBottom: "1px solid",

    "@media (max-width: 600px)": {
      height: "5rem",
    },
  },
  TabActive: {
    height: "100%",
    borderBottom: "5px solid",
  },
  TabDesActive: {
    height: "100%",
    color: "#BFBFBF",
  },
  textTab: {
    textAlign: "center",
    marginLeft: "1rem",
    fontSize: "22px",
    fontFamily: "Helvetica",
    font: "400 18px/22px Helvetica Neue",
    cursor: "pointer",
    marginTop: "1rem",
    "@media (max-width: 600px)": {
      fontSize: "20px",
      marginBottom: "2rem",
    },
  },
  ContenedorTabla: {
    // display: "flex",
    marginTop: "-4rem",
    marginBottom: "1rem",
    position: "relative",
  },
  ContenedorTabla2: {
    marginTop: "0rem",
    marginBottom: "3rem",
    position: "relative",
  },
  titulotabla: {
    color: "#1B5196",
    textTransform: "uppercase",
    fontSize: "18px",
    font: "Bold 18px/13px Helvetica",
  },
  whitetext: {
    color: "white",
  },

  containerEmail: {
    marginTop: "1rem",
  },

  //   Calculadora Persona
  ContenedorBlanco: {
    width: "1088px",
    height: "450px",
    marginBottom: "4.5rem",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      height: "1012px",
      width: "auto",
    },
  },
  DividerAzul: {
    width: "44px",
    height: "0px",
    border: "2px solid #1B5196",
    marginTop: "4px",
    marginBottom: "3%",
  },
  Contenedor1: {
    marginTop: "5%",
    height: "85%",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  inputcustom: {
    color: "BFBFBF",
    "& label.Mui-focused": {
      color: "BFBFBF",
    },
    "& ::label": {
      color: "red",
    },
    "&:focus": {
      borderColor: " #1B5196",
      transition: "border-color 0.3s ease-out",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "BFBFBF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "BFBFBF",
    },
    "& ::placeholder": {
      color: "#BFBFBF",
    },
    "& .MuiSelect-icon": {
      color: "#1B5196",
    },
    "& .MuiSelect-root": {
      color: "#BFBFBF",
    },
    "& .MuiInputBase-input": {
      color: "#BFBFBF",
    },
    "& .MuiInput-input": {
      color: "#000000",
    },
  },
  ContenedorRadio: {
    marginLeft: "5rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  cardText: {
    fontSize: "40px",
  },
  //   Calculadora Grupo
  ContenedorBlancoGrupo: {
    width: "100%",
    marginBottom: "4.5rem",
    marginTop: "2rem",
    transition: "0.5s",
    [theme.breakpoints.down("xs")]: {
      height: "1012px",
      width: "400px",
    },
  },
  inputcustom: {
    color: "BFBFBF",
    "& label.Mui-focused": {
      color: "BFBFBF",
    },
    "&:focus": {
      borderColor: " #1B5196",
      transition: "border-color 0.3s ease-out",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "BFBFBF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "BFBFBF",
    },
    "& ::placeholder": {
      color: "#BFBFBF",
    },
    "& .MuiSelect-icon": {
      color: "#1B5196",
    },
    "& .MuiSelect-root": {
      color: "#BFBFBF",
    },
    "& .MuiInputBase-input": {
      color: "#BFBFBF",
    },
    "& .MuiInput-input": {
      color: "#000000",
    },
  },
  Label: {
    color: "#1B5196",
  },
  ContenedorRadio: {
    marginLeft: "4rem",
    marginTop: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },

  Addgrouptitle: {
    color: "#1B5196 !important",
    fontSize: "25px",
    fontWeight: "800",
  },
  titleGroup: {
    color: "#1B5196",
    fontWeight: "800",
    marginLeft: "1rem",
    marginBottom: "0.5rem",
    fontSize: "22px",
  },
  deleteGroup: {
    color: "#1B5196",
    fontWeight: "800",
    marginLeft: "-1.5rem",
    marginBottom: "0.5rem",
    fontSize: "2rem",
    cursor: "pointer",
  },
}))

export const StyledButton = withStyles({
  root: {
    background: "#1B5196",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "182px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
  },
  label: {
    textTransform: "capitalize",
    fontFamily: "Helvetica",
    font: "Regular 20px/24px Helvetica Neue",
  },
})(MyButton)
