/* REACT MODULES  */
import React, { useState, useEffect } from "react"

/* MATERIAL UI */
import { Grid, TextField, Typography } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"

/* NPM FILES */
import * as validator from "validator"

/* LOCAL FILES */
import UseApi from "../../hooks/useApi"
import CalcularGrupos from "./CalculadoraGrupos"
import CalcularPersona from "./CalculadoraPersona"
// import Table from "../Table/Table"
import TablePagination from "../Table/TablePagination"
import {
  GET_CALCULATE,
  DELETE_COTIZACION,
  SEND_EMAIL,
} from "../../constanst/endpoints"
import Progres from "../Progress"
import { getCurrentUser } from "../../utils/auth"
import ModalIva from "../ModalIva"
import Button from "../Button"

/* STYLES */
import { useStyles } from "./styles"

const SectionOnePage2 = () => {
  const classes = useStyles()
  const [Tabnumber, setTabnumber] = useState(1)
  const [Email1, setEmail1] = useState("")
  const [Email2, setEmail2] = useState("")
  const [Email3, setEmail3] = useState("")
  const { response, get, post, errorApi } = UseApi()
  const [showProgress, setShowProgress] = useState(false)
  const [RetencionIva, setRetencionIva] = useState(false)
  const [dataTableReal, setdataTableReal] = useState([])
  const [dataTablePropuesta, setdataTablePropuesta] = useState([])
  const [SueldoBruo, setSueldoBruo] = useState(0)
  const [ImsyRcv, setImsyRcv] = useState(0)
  const [SueldoMensual, setSueldoMensual] = useState(0)
  const [ISR, setISR] = useState(0)
  const [Objetivo, setObjetivo] = useState(0)
  const [Aportacion, setAportacion] = useState(0)
  const [SueldoPropuesta, setSueldoPropuesta] = useState(0)
  const [ImsyrcvPropuesta, setImsyrcvPropuesta] = useState(0)
  const [IsrPropuesta, setIsrPropuesta] = useState(0)
  const [SueldoNetoPropuesta, setSueldoNetoPropuesta] = useState(0)
  const [AportacionPropuesta, setAportacionPropuesta] = useState(0)
  const [PersepccionPropuesta, setPersepccionPropuesta] = useState(0)
  const [SueldoNetoMensualPropuesta, setSueldoNetoMensualPropuesta] = useState(
    0
  )

  const [modalIvaDisplay, setmodalIvaDisplay] = useState(false)
  const [MensajeModal, setMensajeModal] = useState("")
  const [showModal, setshowModal] = useState(false)
  const infouser = getCurrentUser()
  let TotalSueldoBruto = 0
  let TotalImssyRCV = 0
  let TotalSueldoNetoMensual = 0
  let Totalisr = 0
  let TotalObjetivo = 0
  let Totalaportacion = 0
  let TotalSueldoBrutoPropuesta = 0
  let TotalImssyRCVPropuesta = 0
  let TotalIsrPropuesta = 0
  let totalsieldonetoPropuesta = 0
  let TotalaportacionPropuesta = 0
  let sueldoneto_mensual_propuesta = 0
  let TotalPersepccion = 0
  const ChangeTab = number => {
    setTabnumber(number)
  }
  const handlechangeEmail1 = e => {
    setEmail1(e.target.value)
  }
  const handlechangeEmail12 = e => {
    setEmail2(e.target.value)
  }
  const handlechangeEmail13 = e => {
    setEmail3(e.target.value)
  }
  const handleChangeIva = () => {
    setRetencionIva(!RetencionIva)
  }
  const showModalIva = () => {
    setmodalIvaDisplay(!modalIvaDisplay)
  }

  useEffect(() => {
    if (response.headerResponse) {
      const { headerResponse, payload } = response
      const { code } = headerResponse
      const { queryValores } = payload
      setShowProgress(false)
      if (code === 200) {
        if (headerResponse.message === "Se envió correctamente el correo") {
          ReiniciarCotizacion()
          setMensajeModal(headerResponse.message)
          setshowModal(true)
          setEmail1("")
          setEmail2("")
          setEmail3("")
          showModalIva()
        }
        if (queryValores) {
          if (queryValores.deletedCount !== undefined) {
            GetCotizaciones()
          } else {
            const DataReal = []
            const DataPropuesta = []
            queryValores.map((item, index) => {
              //suma de los totales para el sueldo real
              TotalSueldoBruto = TotalSueldoBruto + item.Sueldo_bruto_mensual
              TotalImssyRCV = TotalImssyRCV + item.imss_retenido
              Totalisr = Totalisr + item.isr_subsidio1
              TotalSueldoNetoMensual += item.sueldoneto_mensual
              TotalObjetivo += item.cantidad_objetivo_sueldo
              Totalaportacion += item.aportacion_patronal
              //suma para los totales de la propuesta
              TotalSueldoBrutoPropuesta += item.sueldobrutofiscal_propuesta
              TotalImssyRCVPropuesta += item.imsretenido_propuesta
              TotalIsrPropuesta += item.isr_subsidio_propuesta
              totalsieldonetoPropuesta += item.sueldonetofiscalmensual_propuesta
              TotalaportacionPropuesta += item.aportacion_propuesta
              sueldoneto_mensual_propuesta += item.sueldoneto_mensual
              TotalPersepccion += item.percepcion_propuesta

              DataReal.push([
                index + 1,
                item.nombre,
                item.salario_diario,
                item.sueldo_diario_integrado,
                item.Sueldo_bruto_mensual,
                item.imss_retenido,
                item.isr_subsidio1.toFixed(2),
                item.sueldoneto_mensual,
                item.cantidad_objetivo_sueldo,
                item.aportacion_patronal,
              ])
              DataPropuesta.push([
                index + 1,
                item.nombre,
                item.salariosdiario_propuesta,
                item.sueldodiariointegrado_propuesta,
                item.sueldobrutofiscal_propuesta,
                item.imsretenido_propuesta,
                item.isr_subsidio_propuesta,
                item.sueldonetofiscalmensual_propuesta,
                item.percepcion_propuesta,
                item.sueldoneto_mensual,
                item.aportacion_propuesta,
              ])
              return
            })
            setSueldoBruo(TotalSueldoBruto)
            setImsyRcv(TotalImssyRCV)
            setSueldoMensual(TotalSueldoNetoMensual)
            setISR(Totalisr)
            setObjetivo(TotalObjetivo)
            setAportacion(Totalaportacion)
            setSueldoPropuesta(TotalSueldoBrutoPropuesta)
            setImsyrcvPropuesta(TotalImssyRCVPropuesta)
            setIsrPropuesta(TotalIsrPropuesta)
            setSueldoNetoPropuesta(totalsieldonetoPropuesta)
            setAportacionPropuesta(TotalaportacionPropuesta)
            setSueldoNetoMensualPropuesta(sueldoneto_mensual_propuesta)
            setPersepccionPropuesta(TotalPersepccion)

            DataReal.push([
              <Typography className={classes.whitetext}>Total</Typography>,
              "",
              "",
              "",
              <Typography className={classes.whitetext}>
                {TotalSueldoBruto.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                {TotalImssyRCV.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                {Totalisr.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                {TotalSueldoNetoMensual.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                {TotalObjetivo.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                {Totalaportacion.toFixed(2)}
              </Typography>,
            ])
            DataPropuesta.push([
              <Typography className={classes.whitetext}>Total</Typography>,
              "",
              "",
              "",
              <Typography className={classes.whitetext}>
                ${TotalSueldoBrutoPropuesta.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                ${TotalImssyRCVPropuesta.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                ${TotalIsrPropuesta.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                ${totalsieldonetoPropuesta.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                ${TotalPersepccion.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                ${sueldoneto_mensual_propuesta.toFixed(2)}
              </Typography>,
              <Typography className={classes.whitetext}>
                ${TotalaportacionPropuesta.toFixed(2)}
              </Typography>,
            ])
            setdataTableReal(DataReal)
            setdataTablePropuesta(DataPropuesta)
            setShowProgress(false)
          }
        }
      } else {
        setMensajeModal(headerResponse.message)
        setshowModal(true)
      }
    } else {
      GetCotizaciones()
    }
  }, [response])
  useEffect(() => {
    if (errorApi.length > 0) {
      setMensajeModal("Se produjo un error en el servidor")
      setshowModal(true)
      setShowProgress(false)
    }
  }, [errorApi])

  const GetCotizaciones = async () => {
    await setShowProgress(true)
    const headers = {
      Authorization: `${infouser.Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: GET_CALCULATE,
      headers,
    }
    await get(query)
  }

  const ReiniciarCotizacion = async () => {
    await setShowProgress(true)
    const headers = {
      Authorization: `${infouser.Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: DELETE_COTIZACION,
      headers,
    }
    await post(query)
  }

  const SendEmail = async () => {
    await setShowProgress(true)
    const headers = {
      Authorization: `${infouser.Token}`,
      "Content-type": "application/json; charset=UTF-8",
    }
    const query = {
      endpoint: SEND_EMAIL,
      headers,
      data: {
        iva: RetencionIva,
        destino: [Email1, Email2, Email3],
        total_sueldobruto: SueldoBruo,
        total_ims_rcv: ImsyRcv.toFixed(2),
        total_isr_sub: ISR.toFixed(2),
        total_snm: SueldoMensual.toFixed(2),
        total_aporacion_ims_rcv: Aportacion.toFixed(2),
        total_sueldobruto_m: SueldoPropuesta.toFixed(2),
        total_ims_rcv_mas: ImsyrcvPropuesta.toFixed(2),
        total_isr: IsrPropuesta.toFixed(2),
        total_sueldo_neto_fiscal: SueldoNetoPropuesta.toFixed(2),
        total_percepcion: PersepccionPropuesta.toFixed(2),
        total_sueldo_neto_mensual: SueldoNetoMensualPropuesta.toFixed(2),
        total_aportacion_mas: AportacionPropuesta.toFixed(2),
      },
    }
    await post(query)
  }
  return (
    <div className={classes.contenedor1}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          xs={10}
          md={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography className={classes.Titulo}>COTIZADOR</Typography>
        </Grid>
        <Grid>
          <Grid
            xs={10}
            md={12}
            className={classes.ContenedorGlobal}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid
              xs={10}
              md={12}
              className={classes.ContenedorTabs}
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                xs={6}
                md={3}
                className={
                  Tabnumber === 1 ? classes.TabActive : classes.TabDesActive
                }
                onClick={() => ChangeTab(1)}
              >
                <Typography className={classes.textTab}>
                  Cotización por persona
                </Typography>
              </Grid>
              <Grid
                xs={6}
                md={4}
                className={
                  Tabnumber === 2 ? classes.TabActive : classes.TabDesActive
                }
                onClick={() => ChangeTab(2)}
              >
                <Typography className={classes.textTab}>
                  Cotización por grupo
                </Typography>
              </Grid>
            </Grid>
            {Tabnumber === 1 ? (
              <CalcularPersona
                sendPeticion={GetCotizaciones}
                deleteCotizacion={ReiniciarCotizacion}
                Ncotizacion={dataTableReal}
                RetencionDeIva={handleChangeIva}
              />
            ) : (
              <CalcularGrupos
                sendPeticion={GetCotizaciones}
                deleteCotizacion={ReiniciarCotizacion}
                Ncotizacion={dataTableReal}
                RetencionDeIva={handleChangeIva}
              />
            )}
            {dataTableReal.length > 1 && (
              <>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={11}
                    className={classes.ContenedorTabla}
                  >
                    <Grid
                      xs={12}
                      md={12}
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="flex-start"
                    >
                      <Grid
                        container
                        xs={10}
                        md={12}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid
                          md={3}
                          xs={12}
                          className={classes.ContenedorRadio}
                        >
                          <Typography className={classes.titulotabla}>
                            Resumen sueldo real
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <TablePagination
                      tableHeaderColor="background"
                      tableHead={[
                        "#",
                        "Nombre",
                        "Sueldo real diario",
                        "Sueldo diario integrado",
                        "Sueldo bruto fiscal mensual",
                        "IMSS y RCV retenido trabajador",
                        "ISR/Subsidio retenido trabajador",
                        "Sueldo neto mensual",
                        "Percepción del sindicato",
                        "Sueldo neto total mensual",
                        "Aportación patronal IMSS, INFONAVIT, RCV",
                      ]}
                      tableData={dataTablePropuesta}
                      total={true}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={11}
                    className={classes.ContenedorTabla2}
                  >
                    <Grid
                      xs={12}
                      md={12}
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="flex-start"
                    >
                      <Grid
                        container
                        xs={10}
                        md={12}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid
                          md={3}
                          xs={12}
                          className={classes.ContenedorRadio}
                        >
                          <Typography className={classes.titulotabla}>
                            PROPUESTA
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <TablePagination
                      tableHeaderColor="background"
                      tableHead={[
                        "#",
                        "Nombre",
                        "Sueldo real diario",
                        "Sueldo diario integrado",
                        "Sueldo bruto fiscal mensual",
                        "IMSS y RCV retenido trabajador",
                        "ISR/Subsidio retenido trabajador",
                        "Sueldo neto mensual",
                        "Percepción del sindicato",
                        "Sueldo neto total mensual",
                        "Aportación patronal IMSS, INFONAVIT, RCV",
                      ]}
                      tableData={dataTablePropuesta}
                      total={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={11}
                    className={classes.ContenedorTabla2}
                  >
                    <Grid
                      xs={12}
                      md={12}
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="flex-start"
                    >
                      <Grid
                        container
                        xs={10}
                        md={12}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid
                          md={3}
                          xs={12}
                          className={classes.ContenedorRadio}
                        >
                          <Typography className={classes.titulotabla}>
                            ENVIAR CORREO A:
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={10}
                        md={12}
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className={classes.containerEmail}
                      >
                        <Grid item xs={10} md={3}>
                          <InputLabel className={classes.Label}>
                            Correo electrónico
                          </InputLabel>
                          <TextField
                            placeholder="Correo electrónico"
                            id="Nombre"
                            fullWidth
                            className={classes.inputcustom}
                            onChange={handlechangeEmail1}
                            value={Email1}
                          />
                        </Grid>
                        <Grid item xs={10} md={3}>
                          <InputLabel className={classes.Label}>
                            Correo electrónico
                          </InputLabel>
                          <TextField
                            placeholder="Nombre"
                            id="Nombre"
                            fullWidth
                            className={classes.inputcustom}
                            onChange={handlechangeEmail12}
                            value={Email2}
                          />
                        </Grid>
                        <Grid item xs={10} md={3}>
                          <InputLabel className={classes.Label}>
                            Correo electrónico
                          </InputLabel>
                          <TextField
                            placeholder="Nombre"
                            id="Nombre"
                            fullWidth
                            className={classes.inputcustom}
                            onChange={handlechangeEmail13}
                            value={Email3}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        md={12}
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="flex-start"
                        className={classes.containerEmail}
                      >
                        <Grid
                          container
                          xs={4}
                          md={3}
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Button
                            disabled={
                              (Email3 != "" && validator.isEmail(Email3)) ||
                              (Email2 != "" && validator.isEmail(Email2)) ||
                              (Email1 != "" && validator.isEmail(Email1))
                                ? false
                                : true
                            }
                            onClick={() => showModalIva()}
                          >
                            ENVIAR
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <ModalIva
            open={modalIvaDisplay}
            activateFucntion={handleChangeIva}
            calculate={SendEmail}
          />
          <Progres show={showProgress} />
          <Dialog
            open={showModal}
            onClose={() => setshowModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Typography className={classes.cardText}>
                {MensajeModal}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Grid
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button onClick={() => setshowModal(false)}>Cerrar</Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  )
}

export default SectionOnePage2
