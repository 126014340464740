import React from "react"
import { Router } from "@reach/router"
import Home from "../components/Home"
import Isr from "../components/Isr"
import Subsides from "../components/Subsides"
import Values from "../components/Values"
import Calculadora from "../components/Calculadora"
import Users from "../components/Users"
import PrivateRoute from "../components/PrivateRoute"
import NotFound from './404'


const App = () => (
    <Router>
      <PrivateRoute path="/app/home" component={Home} />
      <PrivateRoute path="/app/isr" component={Isr} />
      <PrivateRoute path="/app/subsides" component={Subsides} />
      <PrivateRoute path="/app/values" component={Values} />
      <PrivateRoute path="/app/calculator" component={Calculadora} 
      />
      <PrivateRoute path="/app/users" component={Users} />
      <NotFound default />
    </Router>
)

export default App