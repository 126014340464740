/**
 *  @version 1.0.1
 *  @author Springlabs - LuisHdz
 *  @description Calcula por persona
 * 	@process 1
 */

/* REACT MODULES  */
import React, { useState, useEffect } from "react"

/* MATERIAL UI */
import {
  Grid,
  TextField,
  Button as MyButton,
  Typography,
} from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import CloseIcon from "@material-ui/icons/Close"
import InputAdornment from "@material-ui/core/InputAdornment"
import Button from "../Button"
import ModalGroup from "../ModalGroup"

/* NPM FILES */
import * as validator from "validator"

/* LOCAL FILES */
import Progres from "../Progress"
import Modal from "../Modal"
import UseApi from "../../hooks/useApi"
import { CALCULATE_GROUP } from "../../constanst/endpoints"
import { getCurrentUser } from "../../utils/auth"

/* STYLES */
import { useStyles, StyledButton } from "./styles"

const SectionOnePage2 = ({ sendPeticion, deleteCotizacion, Ncotizacion }) => {
  const classes = useStyles()
  const { response, post, errorApi } = UseApi()
  const [numberGroups, setnumberGroups] = useState([
    {
      id: 0,
      numeroDeEmplados: 0,
      ComisionPropuesta: 0,
      intervaloDePago: "",
      SueldoBruto: "",
      ObjetivoNeto: "",
      SalarioImsdiario: 0,
    },
  ])
  const [number, setnumber] = useState(9)
  const [showProgress, setShowProgress] = useState(false)
  const [showModalmoreGroups, setshowModalmoreGroups] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [buttonDisabledGroup, setbuttonDisabledGroup] = useState(true)
  const [ErrorImss, setErrorImss] = useState(false)
  const [MensajeModal, setMensajeModal] = useState("")
  const infouser = getCurrentUser()

  const handlechangeValues = (e, index, type) => {
    const Groups = numberGroups
    const value = e.target.value
    const caracteravalidar = value.charAt(value.length - 1)
    switch (type) {
      case "numeroDeEmplados":
        if (validator.isNumeric(caracteravalidar)) {
          if (value < 1000) {
            Groups[index].numeroDeEmplados = parseInt(value)
          } else {
            const sustrae = value.substring(0, value.length - 1)
            Groups[index].numeroDeEmplados = sustrae
          }
        } else {
          const sustrae = value.substring(0, value.length - 1)
          Groups[index].numeroDeEmplados = sustrae
        }
        break
      case "ComisiónPropuesta":
        if (validator.isNumeric(caracteravalidar) === true) {
          Groups[index].ComisionPropuesta = parseInt(value)
        } else {
          const sustrae = value.substring(0, value.length - 1)
          Groups[index].ComisionPropuesta = sustrae
        }
        break

      case "intervaloDePago":
        Groups[index].intervaloDePago = value
        break
      case "SueldoBruto":
        if (validator.isNumeric(caracteravalidar) === true) {
          Groups[index].SueldoBruto = parseInt(value)
        } else {
          const sustrae = value.substring(0, value.length - 1)
          Groups[index].SueldoBruto = sustrae
        }
        break
      case "Objetivo":
        if (validator.isNumeric(caracteravalidar) === true) {
          Groups[index].ObjetivoNeto = parseInt(value)
        } else {
          const sustrae = value.substring(0, value.length - 1)
          Groups[index].ObjetivoNeto = sustrae
        }
        break
      case "SalarioImss":
        if (validator.isNumeric(caracteravalidar) === true) {
          Groups[index].SalarioImsdiario = parseInt(value)
        } else {
          const sustrae = value.substring(0, value.length - 1)
          Groups[index].SalarioImsdiario = sustrae
        }
        break
      default:
        break
    }
    setnumberGroups([...numberGroups])
    ValidateAddButton()
  }

  const SendData = async () => {
    setshowModalmoreGroups(false)
    numberGroups.map((item, index) => {
      if (item.SalarioImsdiario > 0 && item.SalarioImsdiario < 149) {
        setshowModal(true)
        setMensajeModal(
          `${MensajeModal} No pudimos calcular el grupo ${index +
            1} ingresa un salario del IMSS mayor a $ 150 e intentalo nuevamente `
        )
        setErrorImss(true)
        return
      } else {
        if (item.ObjetivoNeto === item.SueldoBruto) {
          setMensajeModal(
            `${MensajeModal} No pudimos calcular el grupo ${index +
              1} ingresa un sueldo bruto o un objetivo neto `
          )
          setshowModal(true)
        } else {
          setShowProgress(true)
          setErrorImss(false)
          const headers = {
            Authorization: `${infouser.Token}`,
            "Content-type": "application/json; charset=UTF-8",
          }
          const query = {
            endpoint: CALCULATE_GROUP,
            headers,
            data: {
              numero_Emplados: item.numeroDeEmplados,
              comision_Propuesta: parseInt(item.ComisionPropuesta),
              intervalo_Pago: item.intervaloDePago,
              sueldo_Bruto:
                item.SueldoBruto === "" ? 0 : parseInt(item.SueldoBruto),
              sueldo_Neto:
                item.ObjetivoNeto === "" ? 0 : parseInt(item.ObjetivoNeto),
              salario_Ims: parseInt(item.SalarioImsdiario),
            },
          }
          post(query)
        }
      }
    })
  }

  const addGroup = () => {
    setnumber(number + 1)
    numberGroups.push({
      id: numberGroups.length,
      numeroDeEmplados: 0,
      ComisionPropuesta: 0,
      intervaloDePago: 0,
      SueldoBruto: 0,
      ObjetivoNeto: 0,
      SalarioImsdiario: 0,
    })
    setnumberGroups(numberGroups)
    ValidateAddButton()
  }

  const ValidateAddButton = () => {
    const Data = numberGroups[numberGroups.length - 1]
    if (numberGroups.length > 19) {
      setbuttonDisabledGroup(true)
    }
    if (
      Data.numeroDeEmplados != 0 &&
      Data.ComisionPropuesta != 0 &&
      Data.intervaloDePago != ""
    ) {
      if (Data.SueldoBruto != Data.ObjetivoNeto) {
        if (Data.ObjetivoNeto != 0 && Data.ObjetivoNeto < 4000) {
          setbuttonDisabledGroup(true)
        } else {
          setbuttonDisabledGroup(false)
        }
      } else {
        setbuttonDisabledGroup(true)
      }
    } else {
      setbuttonDisabledGroup(true)
    }
  }
  const restar = () => {
    deleteCotizacion()
    setnumberGroups([
      {
        id: 0,
        numeroDeEmplados: 0,
        ComisionPropuesta: 0,
        intervaloDePago: "",
        SueldoBruto: 0,
        ObjetivoNeto: 0,
        SalarioImsdiario: 0,
      },
    ])
  }
  useEffect(() => {
    if (response.headerResponse) {
      const { headerResponse } = response
      if (headerResponse.code === 200) {
        setShowProgress(false)
        sendPeticion(true)
        setnumberGroups([
          {
            id: 0,
            numeroDeEmplados: 0,
            ComisionPropuesta: 0,
            intervaloDePago: "",
            SueldoBruto: 0,
            ObjetivoNeto: 0,
            SalarioImsdiario: 0,
          },
        ])
        ValidateAddButton()
      } else if (headerResponse.code === 204) {
        setshowModal(true)
        setMensajeModal(headerResponse.message)
      }
    }
  }, [response])

  useEffect(() => {
    if (errorApi.length > 0) {
      setMensajeModal(errorApi)
      setshowModal(true)
      setShowProgress(false)
    }
  }, [errorApi])

  const addMoreGroups = () => {
    setshowModalmoreGroups(!showModalmoreGroups)
  }
  const deleteGroup = position => {
    numberGroups.splice(position, 1)
    setnumberGroups([...numberGroups])
    ValidateAddButton()
  }

  return (
    <div>
      <Grid style={{ height: "auto" }}>
        <Grid container className={classes.ContenedorBlancoGrupo}>
          <Grid
            container
            xs={10}
            md={12}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid md={12} xs={12} className={classes.ContenedorRadio}>
              <Typography style={{ color: "#1B5196", fontWeight: "normal" }}>
                Puedes agregar uno o más grupos a cotizar.
              </Typography>
            </Grid>
          </Grid>
          {numberGroups.map((item, index) => {
            return (
              <Grid
                xs={9}
                md={12}
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
              >
                <Grid
                  xs={9}
                  md={12}
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="center"
                >
                  <Grid container xs={11} md={11}>
                    <Grid xs={4} md={4}>
                      <Typography className={classes.titleGroup}>
                        Grupo {index + 1}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={7}
                      md={7}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Typography className={classes.titleGroup}>
                        {index !== 0 && (
                          <CloseIcon
                            className={classes.deleteGroup}
                            onClick={() => deleteGroup(index)}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={10} md={3}>
                    <InputLabel className={classes.Label}>
                      Número de empleados:
                    </InputLabel>
                    <TextField
                      placeholder="Número de empleados"
                      id="Número de empleados"
                      name="Número de empleados"
                      name="Numero de empleados"
                      fullWidth
                      className={classes.inputcustom}
                      onChange={e =>
                        handlechangeValues(e, index, "numeroDeEmplados")
                      }
                      value={
                        item.numeroDeEmplados === 0 ? "" : item.numeroDeEmplados
                      }
                    />
                  </Grid>
                  <Grid item xs={10} md={3}>
                    <InputLabel className={classes.Label}>
                      Comisión propuesta:
                    </InputLabel>
                    <TextField
                      placeholder="Comisión propuesta:"
                      id="Nombre"
                      fullWidth
                      className={classes.inputcustom}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      onChange={e =>
                        handlechangeValues(e, index, "ComisiónPropuesta")
                      }
                      value={
                        item.ComisionPropuesta === 0
                          ? ""
                          : item.ComisionPropuesta
                      }
                    />
                  </Grid>
                  <Grid item xs={10} md={3}>
                    <InputLabel className={classes.Label}>
                      Selecciona el intervalo de pago:
                    </InputLabel>
                    <TextField
                      select
                      placeholder="Nombre"
                      id="Nombre"
                      fullWidth
                      className={classes.inputcustom}
                      onChange={e =>
                        handlechangeValues(e, index, "intervaloDePago")
                      }
                      value={item.intervaloDePago}
                    >
                      <MenuItem value="Semanal">Semanal</MenuItem>
                      <MenuItem value="Catorcenal">Catorcenal</MenuItem>
                      <MenuItem value="Quincenal">Quincenal</MenuItem>
                      <MenuItem value="Mensual">Mensual</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  md={12}
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="center"
                >
                  <Grid item xs={10} md={3}>
                    <InputLabel className={classes.Label}>
                      Indica el sueldo Bruto:
                    </InputLabel>
                    <TextField
                      placeholder="Indica el sueldo Bruto:"
                      id="SueldoBruto"
                      fullWidth
                      className={classes.inputcustom}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={item.SueldoBruto === 0 ? "" : item.SueldoBruto}
                      onChange={e =>
                        handlechangeValues(e, index, "SueldoBruto")
                      }
                      disabled={item.ObjetivoNeto > 0}
                    />
                  </Grid>
                  <Grid item xs={10} md={3}>
                    <InputLabel className={classes.Label}>
                      Cantidad objetivo sueldo neto:
                    </InputLabel>
                    <TextField
                      placeholder="Cantidad objetivo sueldo neto:"
                      helperText="Mayor o igual $4,000"
                      id="Objetivo"
                      fullWidth
                      className={classes.inputcustom}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={item.ObjetivoNeto === 0 ? "" : item.ObjetivoNeto}
                      onChange={e => handlechangeValues(e, index, "Objetivo")}
                      disabled={item.SueldoBruto > 0}
                    />
                  </Grid>
                  <Grid item xs={10} md={3}>
                    <InputLabel className={classes.Label}>
                      Indica salario diario a cotizar en IMSS:
                    </InputLabel>
                    <TextField
                      placeholder="Indica salario diario a cotizar en IMSS"
                      helperText="Mayor o igual a $150"
                      id="SalarioImss"
                      fullWidth
                      className={classes.inputcustom}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={
                        item.SalarioImsdiario === 0 ? "" : item.SalarioImsdiario
                      }
                      onChange={e =>
                        handlechangeValues(e, index, "SalarioImss")
                      }
                      error={ErrorImss}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
          <Grid
            xs={12}
            md={12}
            container
            direction="row"
            justify="space-evenly"
            alignItems="flex-start"
          >
            <Grid
              container
              xs={10}
              md={12}
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Grid md={3} xs={12} className={classes.ContenedorRadio}>
                <Button
                  onClick={() => addGroup()}
                  disabled={buttonDisabledGroup}
                >
                  Agregar grupo
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            md={12}
            container
            direction="row"
            justify="space-evenly"
            alignItems="flex-start"
            className={classes.div2}
          >
            <Grid
              container
              xs={4}
              md={5}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <StyledButton
                onClick={numberGroups.length === 20 ? SendData : addMoreGroups}
                disabled={buttonDisabledGroup}
              >
                Calcular
              </StyledButton>
              <StyledButton
                onClick={() => restar()}
                disabled={Ncotizacion.length > 1 ? false : true}
                style={{ marginLeft: "2rem" }}
              >
                Reiniciar
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalGroup
        open={showModalmoreGroups}
        calculate={SendData}
        addGroup={addMoreGroups}
        nGropus={numberGroups.length}
      />
      <Progres show={showProgress} />
      {showModal && (
        <Modal noButton>
          <Typography className={classes.cardText}>{MensajeModal}</Typography>
          <StyledButton
            onClick={() => {
              setshowModal(false)
              setMensajeModal("")
            }}
          >
            Cerrar
          </StyledButton>
        </Modal>
      )}
      {showModal && (
        <Modal noButton>
          <Typography className={classes.cardText}>{MensajeModal}</Typography>
          <StyledButton
            onClick={() => {
              setshowModal(false)
              setMensajeModal("")
            }}
          >
            Cerrar
          </StyledButton>
        </Modal>
      )}
    </div>
  )
}

export default SectionOnePage2
