const tableStyle = () => ({
  tableHeader: {
    color: "#1B5196",
    borderBottom: "2px solid #1B5196",
  },
  tableBody: {
    color: "#FFF",
    backgroundColor: "#5351FB",
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "inherit",
    "&, &$tableCell": {
      fontSize: "1em",
    },
  },
  tableCell: {
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
  },
  tableResponsive: {
    width: "100%",
    overflowX: "auto",
  },
  tableHeadRow: {
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
    borderBottom: "4px solid",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyTotal: {
    height: "48px",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
    backgroundColor: "#1B5196 !important",
    background: "#1B5196 !important",
  },
  tableBodyWhite: {
    color: "#FFF",
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
  },
})

export default tableStyle
