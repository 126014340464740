import React, { useState } from "react"
import { Button as MyButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: "#1B5196",
    textTransform: "uppercase",
    color: "white",
    borderRadius: "27px",
    border: 0,
    padding: "1rem 2rem",
    marginBottom: "2rem",
    marginLeft: "2rem",
    transition: "0.5s",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: "#71BFD0",
      marginTop: "1rem",
      boxshadow: "0px 3px 6px #0000006A",
    },
  },
}))

function Button(props) {
  const classes = useStyles()
  const { children, disabled, onClick } = props

  return (
    <>
      <MyButton
        className={classes.button}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </MyButton>
    </>
  )
}

export default Button
