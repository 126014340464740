import { makeStyles } from "@material-ui/core/styles"
import background from "../images/66.png"
import background2 from "../images/662.png"
import FondoReloj from "../images/376565-PBFZIA-878.png"
import FondoReloj2 from "../images/376565-PBFZIA-8782.png"
import FondoManos from "../images/811.png"
import FondoManos2 from "../images/8112.png"
import Recursos_humanos from "../images/25972792.png"
import Recursos_humanos1 from "../images/Recursos_humanos.png"

const useStyles = makeStyles(theme => ({
  Contenedor1: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#FFB796",
    backgroundSize: "cover",
    width: "1367px",
    height: "912px",
    top: "-144px",
    overflowX: "hiden",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${background2})`,
      backgroundColor: "#FFB796",
    },
  },
  Contenedor2: {
    marginTop: "28%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "13%",
    },
  },
  TituloAzul: {
    color: "#1B5196",
    fontSize: "45px",
    letterSpacing: "6.62px",
    opacity: "1",
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
    },
  },
  TituloBlanco: {
    fontSize: "39px",
    letterSpacing: "0px",
    color: "#FFFFFF",
    textAlign: "center",
    textShadow: "0px 3px 6px #861515",
    [theme.breakpoints.down("xs")]: {
      fontSize: "29px",
    },
  },
  Parrafo: {
    textAlign: "center",
    fontSize: "24px",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  Button: {
    borderRadius: "15px",
    marginTop: "10px",
    width: "182px",
    height: "45px",
    fontSize: "20px",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  BottonNuevo: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      borderRadius: "15px",
      marginTop: "10px",
      width: "182px",
      height: "45px",
      fontSize: "20px",
      marginBottom: "12rem",
    },
  },
  arrowContent: {
    color: "#FFFFFF",
    marginTop: "10px",
    width: "52px",
    height: "48px",
  },
  arrow: {
    width: "100%",
    height: "100%",
  },
  ContenedorBlanco: {
    width: "100%",
    height: "812px",
    backgroundColor: "#F2F2F2",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  TituloAzul2: {
    color: "#1B5196",
    fontSize: "45px",
    textAlign: "center",
    letterSpacing: "6.62px",
    marginTop: "8%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "50%",
    },
  },
  TituloAzul22: {
    color: "#1B5196",
    fontSize: "45px",
    textAlign: "center",
    letterSpacing: "6.62px",
    marginTop: "8%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
    },
  },
  avatars: {
    marginTop: "0rem",
    width: "216px",
    height: "216px",
    [theme.breakpoints.down("xs")]: {
      width: "116px",
      height: "116px",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  },
  Parrafo2: {
    fontSize: "25px",
    marginTop: "2rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginRight: "2rem",
      marginLeft: "2rem",
    },
  },
  Parrafo22: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginRight: "2rem",
      marginLeft: "2rem",
      fontSize: "20px",
      textAlign: "center",
    },
  },
  cardImage: {
    marginBottom: "3rem",
    width: "90%",
  },
  ContenedorBlanco2: {
    width: "100%",
    height: "812px",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  ContenedorGris: {
    height: "812px",
    width: "100%",
    backgroundColor: "#EAEAEA",
    backgroundImage: `url(${Recursos_humanos1})`,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${Recursos_humanos})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
    },
  },
  imagenFondo: {
    width: "100%",
    height: "812px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  ButtonForm: {
    display: "block",
  },
  Containter: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  Button2: {
    borderRadius: "15px",
    width: "182px",
    height: "45px",
    fontSize: "20px",
    position: "absolute",
    bottom: "27rem",
    right: "58rem",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  Parrafo3: {
    fontSize: "25px",
    marginTop: "0rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "90% !important",
    },
  },
  TexoBen: {
    fontSize: "17px",
    marginTop: "0rem",
    textAlign: "center",
  },
  TituloAzul3: {
    color: "#1B5196",
    fontSize: "45px",
    textAlign: "center",
    letterSpacing: "6.62px",
    marginTop: "1%",
  },
  TituloAzul33: {
    color: "#1B5196",
    fontSize: "45px",
    textAlign: "center",
    letterSpacing: "6.62px",
    marginTop: "1%",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  ContenedorFondo2: {
    backgroundImage: `url(${FondoManos})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    width: "1367px",
    overflowX: "hiden",
    height: "1171px",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${FondoManos2})`,
      height: "auto",
      backgroundColor: "#FFB796",
    },
  },
  TituloAzulBlanco: {
    color: "#FFFFFF",
    fontSize: "45px",
    textAlign: "center",
    letterSpacing: "7.35px",
    marginTop: "5%",
    marginBottom: "0%",
    textShadow: "0px 3px 2px #00000089",
  },
  DividerBlanco: {
    width: "44px",
    height: "0px",
    border: "2px solid #FFFFFF",
    marginTop: "4px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20rem",
    },
  },
  TituloCard: {
    color: "#1B5196",
    fontSize: "20px",
    textAlign: "center",
  },
  SubTituloCard: {
    color: "#1B5196",
    fontSize: "15px",
    textAlign: "center",
  },
  TextoCard: {
    color: "#000000",
    fontSize: "15px",
    textAlign: "center",
  },
  Card1: {
    marginTop: "15rem",
    width: "382px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  Card2: {
    marginTop: "1rem",
    width: "382px",
  },
  Card3: {
    marginTop: "1rem",
    width: "382px",
    height: "115px",
  },
  Card33: {
    marginTop: "1rem",
    width: "382px",
    height: "115px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "3rem",
    },
  },
  DividerAzul: {
    width: "44px",
    height: "0px",
    border: "2px solid #1B5196",
    marginTop: "4px",
    marginBottom: "3%",
  },
  ContenedorFondo3: {
    backgroundImage: `url(${FondoReloj})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    width: "1267px",
    overflowX: "hiden",
    height: "959px",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${FondoReloj2})`,
      backgroundColor: "#00000029",
      height: "812px",
    },
  },
  ContenedorBlanco3: {
    width: "100%",
    height: "1013px",
    backgroundColor: "#F2F2F2",
  },
  Parrafo4: {
    fontSize: "25px",
    marginTop: "0rem",
    textAlign: "center",
    marginBottom: "3rem",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  ImagenLogo: {
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}))

export default useStyles
