import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import notificationStyle from "./notificationStyle"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles(notificationStyle)

const TransparentButton = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 10,
    color: "#1B5196",
    "&:hover, &$focusVisible": {
      backgroundColor: "transparent",
      color: "#94DBF6",
    },
  },
})(Button)

const CancelButton = withStyles({
  root: {
    backgroundColor: "#800000",
    borderRadius: 50,
    border: 0,
    color: "#FFF",
    fontSize: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    "&:hover, &$focusVisible": {
      backgroundColor: "transparent",
      color: "#800000",
    },
  },
  label: {
    textTransform: "upperCase",
  },
})(Button)

const SuccessButton = withStyles({
  root: {
    backgroundColor: "#1B5196",
    borderRadius: 50,
    border: 0,
    color: "#FFF",
    fontSize: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    "&:disabled": {
      backgroundColor: "#9B9B9B",
    },
    "&:hover, &$focusVisible": {
      backgroundColor: "transparent",
      color: "#1B5196",
    },
  },
  label: {
    textTransform: "upperCase",
  },
})(Button)

export default function Notification(props) {
  const {
    open,
    onClose,
    onOk,
    title,
    deleteNotification,
    okTitle,
    cancelTitle,
    content,
    disabledButton,
  } = props
  const classes = useStyles()

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={deleteNotification ? "sm" : "lg"}
      >
        {!deleteNotification && (
          <TransparentButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon style={{ fontSize: "40" }} />
          </TransparentButton>
        )}
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          <Grid container xs={12} justify="center">
            <Typography
              className={
                deleteNotification ? classes.titleDelete : classes.title
              }
            >
              {title}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={deleteNotification && classes.contentDelete}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "40px" }}
        >
          {deleteNotification && (
            <CancelButton onClick={onClose}>{cancelTitle}</CancelButton>
          )}
          <SuccessButton onClick={onOk} disabled={disabledButton} autoFocus>
            {okTitle}
          </SuccessButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
