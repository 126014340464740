/**
 *  @version 1.0.1
 *  @author Springlabs - LuisHdz
 *  @description Calcula por persona
 * 	@process 1
 */

/* REACT MODULES  */
import React, { useState, useEffect } from "react"

/* MATERIAL UI */
import { Grid, TextField, Typography } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import InputAdornment from "@material-ui/core/InputAdornment"

/* NPM FILES */
import * as validator from "validator"

/* LOCAL FILES */
import Progres from "../Progress"
import Modal from "../Modal"
import UseApi from "../../hooks/useApi"
import { CALCULATE_PERSON } from "../../constanst/endpoints"
import { getCurrentUser } from "../../utils/auth"
import Button from "../Button"

/* STYLES */
import { useStyles } from "./styles"

const SectionOnePage2 = props => {
  const classes = useStyles()
  const { response, post, errorApi } = UseApi()
  const [Nombre, setNombre] = useState("")
  const [ComisionPropuesta, setComisionPropuesta] = useState(0)
  const [intervaloDePago, setintervaloDePago] = useState("")
  const [SueldoBruto, setSueldoBruto] = useState(0)
  const [ObjetivoNeto, setObjetivoNeto] = useState(0)
  const [SalarioImsdiario, setSalarioImsdiario] = useState(0)
  const [showProgress, setShowProgress] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [ButtonDisabled, setButtonDisabled] = useState(true)
  const [MensajeModal, setMensajeModal] = useState("")
  const infouser = getCurrentUser()

  const ValidateButton = () => {
    if (Nombre !== "" && ComisionPropuesta !== "" && intervaloDePago !== "") {
      if (
        Nombre.length === 0 ||
        ComisionPropuesta === 0 ||
        intervaloDePago === ""
      ) {
        setButtonDisabled(true)
      } else if (ObjetivoNeto === 0 && SueldoBruto === 0) {
        setMensajeModal("Indica el sueldo Bruto o un objetivo de sueldo")
        setButtonDisabled(true)
      } else {
        setButtonDisabled(false)
      }
    }
  }

  const handlechangeValues = e => {
    setNombre(e.target.value)
    ValidateButton()
  }
  const handlechangeComision = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (validator.isNumeric(caracteravalidar) === true) {
      setComisionPropuesta(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setComisionPropuesta(sustrae)
    }
    ValidateButton()
  }
  const handlechangeIntervalo = e => {
    setintervaloDePago(e.target.value)
    ValidateButton()
  }
  const handlechangeSueldoBruto = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (validator.isNumeric(caracteravalidar) === true) {
      setSueldoBruto(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setSueldoBruto(sustrae)
    }
    ValidateButton()
  }
  const handlechangeObjetivoNeto = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (validator.isNumeric(caracteravalidar) === true) {
      setObjetivoNeto(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setObjetivoNeto(sustrae)
    }
    ValidateButton()
  }
  const handlechangeSalarioImsdiario = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (validator.isNumeric(caracteravalidar) === true) {
      setSalarioImsdiario(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setSalarioImsdiario(sustrae)
    }
    ValidateButton()
  }

  const SendData = async () => {
    if (SalarioImsdiario > 0 && SalarioImsdiario < 149) {
      setshowModal(true)
      setMensajeModal("Ingresa un salario del IMSS mayor a $ 150")
    } else {
      setShowProgress(true)
      const headers = {
        Authorization: `${infouser.Token}`,
        "Content-type": "application/json; charset=UTF-8",
      }
      const query = {
        endpoint: CALCULATE_PERSON,
        headers,
        data: {
          nombre: Nombre,
          comision_Propuesta: parseInt(ComisionPropuesta),
          intervalo_Pago: intervaloDePago,
          sueldo_Bruto: parseInt(SueldoBruto),
          sueldo_Neto: parseInt(ObjetivoNeto),
          salario_Ims: parseInt(SalarioImsdiario),
        },
      }
      await ValidateButton()
      await post(query)
    }
  }

  useEffect(() => {
    if (response.headerResponse) {
      const { headerResponse } = response
      if (headerResponse.code === 200) {
        setShowProgress(false)
        props.sendPeticion(true)
        setNombre("")
        setComisionPropuesta(0)
        setintervaloDePago("")
        setSueldoBruto(0)
        setObjetivoNeto(0)
        setSalarioImsdiario(0)
        setButtonDisabled(true)
      } else {
        setshowModal(true)
        setMensajeModal(headerResponse.message)
      }
    }
  }, [response])

  useEffect(() => {
    if (errorApi.length > 0) {
      setMensajeModal("Se produjo un error en el servidor")
      setshowModal(true)
      setShowProgress(false)
    }
  }, [errorApi])
  return (
    <div>
      <Grid container className={classes.ContenedorBlanco}>
        <Grid
          xs={10}
          md={12}
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={10} md={3}>
            <InputLabel className={classes.Label}>Nombre:</InputLabel>
            <TextField
              placeholder="Nombre"
              id="Nombre"
              name="Nombre"
              fullWidth
              className={classes.inputcustom}
              onChange={handlechangeValues}
              value={Nombre}
            />
          </Grid>
          <Grid item xs={10} md={3}>
            <InputLabel className={classes.Label}>
              Comisión propuesta:
            </InputLabel>
            <TextField
              placeholder="Comisión propuesta:"
              id="Comisión propuesta:"
              fullWidth
              className={classes.inputcustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              onChange={handlechangeComision}
              value={ComisionPropuesta === 0 ? "" : ComisionPropuesta}
            />
          </Grid>
          <Grid item xs={10} md={3}>
            <InputLabel className={classes.Label}>
              Selecciona el intervalo de pago:
            </InputLabel>
            <TextField
              select
              placeholder="Nombre"
              id="Nombre"
              fullWidth
              className={classes.inputcustom}
              onChange={handlechangeIntervalo}
              value={intervaloDePago}
            >
              <MenuItem value="Semanal">Semanal</MenuItem>
              <MenuItem value="Catorcenal">Catorcenal</MenuItem>
              <MenuItem value="Quincenal">Quincenal</MenuItem>
              <MenuItem value="Mensual">Mensual</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          md={12}
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={10} md={3}>
            <InputLabel className={classes.Label}>
              Indica el sueldo Bruto:
            </InputLabel>
            <TextField
              placeholder="Indica el sueldo Bruto:"
              id="SueldoBruto"
              fullWidth
              className={classes.inputcustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={SueldoBruto === 0 ? "" : SueldoBruto}
              onChange={handlechangeSueldoBruto}
              disabled={ObjetivoNeto.length > 0 ? true : false}
            />
          </Grid>
          <Grid item xs={10} md={3}>
            <InputLabel className={classes.Label}>
              Cantidad objetivo sueldo neto:
            </InputLabel>
            <TextField
              placeholder="Cantidad objetivo sueldo neto:"
              id="Objetivo"
              fullWidth
              className={classes.inputcustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={ObjetivoNeto === 0 ? "" : ObjetivoNeto}
              onChange={handlechangeObjetivoNeto}
              disabled={SueldoBruto.length > 0 ? true : false}
            />
          </Grid>
          <Grid item xs={10} md={3}>
            <InputLabel className={classes.Label}>
              Indica salario diario a cotizar en IMSS:
            </InputLabel>
            <TextField
              placeholder="Indica salario diario a cotizar en IMSS"
              id="SalarioImss"
              fullWidth
              className={classes.inputcustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={SalarioImsdiario === 0 ? "" : SalarioImsdiario}
              onChange={handlechangeSalarioImsdiario}
            />
          </Grid>
        </Grid>

        <Grid
          xs={12}
          md={12}
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.div2}
        >
          <Grid
            container
            xs={4}
            md={5}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button onClick={SendData} disabled={ButtonDisabled}>
              Calcular
            </Button>

            <Button
              onClick={() => props.deleteCotizacion()}
              disabled={props.Ncotizacion.length > 1 ? false : true}
            >
              Reiniciar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Progres show={showProgress} />
      {showModal && (
        <Modal noButton>
          <Typography className={classes.cardText}>{MensajeModal}</Typography>
          <Button onClick={() => setshowModal(false)}>Cerrar</Button>
        </Modal>
      )}
    </div>
  )
}

export default SectionOnePage2
