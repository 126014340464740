/**
 *  @version 1.0.1
 *  @author Springlabs - Luis Hernandez
 *  @description Componente para mostrar el modal el cual responde a la preguntaa ¿aplica retencion de iva?
 * 	@process 2
 */

/* REACT MODULES */
import React from "react"
import PropTypes from "prop-types"

/* MATERIAL UI */
import { makeStyles } from "@material-ui/core/styles"
import {
  Dialog,
  DialogTitle,
  Portal,
  Typography,
  Grid,
  DialogContentText,
  DialogContent,
} from "@material-ui/core"
import Button from "./Button"

const useStyles = makeStyles(theme => ({
  TitleModal: { color: "#1B5196", fontSize: "23px" },
  TextModal: {
    color: "#1B5196",
    fontSize: "16px",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
}))

const ModalGroup = ({ open, maxWidth, addGroup, calculate }) => {
  const classes = useStyles()

  return (
    <Portal>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        id="galileo-dialog"
        aria-labelledby="dialog-title"
      >
        <DialogTitle>
          <Grid
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.TitleModal}>
              Agregar grupo
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography className={classes.TextModal}>
              ¿Estás seguro de no agregar otro grupo a la cotización?
            </Typography>
            <Grid
              xs={10}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid
                xs={6}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button onClick={calculate}>Calcular </Button>
              </Grid>
              <Grid
                xs={6}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button onClick={addGroup}>Agregar Grupo </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Portal>
  )
}

ModalGroup.propTypes = {
  show: PropTypes.bool,
  addGroup: PropTypes.func,
  calculate: PropTypes.func,
}

ModalGroup.defaultProps = {
  show: false,
  addGroup: () => {},
  calculate: () => {},
}

export default ModalGroup
